import { PublicClientApplication } from "@azure/msal-browser";
import { storeToRefs } from "pinia";

import { ErrorDialog } from "shared/boot/alert";
import { $streemApiAdmin, $streemApiV1 } from "shared/boot/api";
import { Organisation } from "shared/resources";
import StorageService from "shared/services/StorageService";
import { useUserStore } from "shared/stores/user";
import actions from "shared/stores/user/actions";

const handleErrors = true;

export default {
  ...actions,
  logoutAdmin: actions.logout,
  googleAuthCallback() {
    return ({ credential }) => {
      $streemApiV1
        .post("sessions", {
          params: {
            provider: "google",
            id_token: credential,
            platform: "admin",
          },
          handleErrors: false,
        })
        .then(async (response) => {
          const token = response.data.auth_token;

          await StorageService.set("token", token);

          this.loginSuccess({ token, userId: response.data.user_id });
        })
        .catch(() => {
          this.loginFailure();
        });
    };
  },
  microsoftAuthCallback() {
    return ({ idToken }) => {
      $streemApiV1
        .post("sessions", {
          params: {
            provider: "microsoft",
            id_token: idToken,
            platform: "admin",
          },
          handleErrors: false,
        })
        .then(async (response) => {
          const token = response.data.auth_token;

          await StorageService.set("token", token);

          this.loginSuccess({ token, userId: response.data.user_id });
        })
        .catch(() => {
          this.loginFailure();
        });
    };
  },
  initGoogleAuth() {
    const { google } = window;

    google.accounts.id.initialize({
      client_id:
        "591696447202-cnffv3kb0q2ufmnmno8inhp1e380h3nj.apps.googleusercontent.com",
      callback: this.googleAuthCallback(),
      auto_select: true,
    });
  },
  initMicrosoftAuth(clientId) {
    const config = {
      auth: {
        clientId,
      },
    };

    const myMsal = new PublicClientApplication(config);

    const loginRequest = {
      scopes: ["User.Read"],
    };

    myMsal.loginPopup(loginRequest).then(this.microsoftAuthCallback());
  },
  logout(clearAll = false) {
    StorageService.remove("token");

    if (clearAll) {
      StorageService.remove("login");
    }

    this.logoutAdmin();
  },
  getCurrentUser() {
    const userStore = useUserStore();
    const { currentUser } = storeToRefs(userStore);

    return $streemApiAdmin.get("me", { handleErrors }).then((response) => {
      this.currentUser = response.data;
      currentUser.value = response.data;
    });
  },
  getOrganisation(organisationId) {
    return Organisation.find(organisationId)
      .then((response) => {
        this.organisation = response.data;
      })
      .catch((error) => ErrorDialog("Failed to load organisation", error));
  },
  updateOrganisation({ organisationId, params }) {
    return $streemApiAdmin
      .put(`organisations/${organisationId}`, { params, handleErrors })
      .then((response) => {
        this.organisation = { organisationId, data: response.data };
      });
  },
};
