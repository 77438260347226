import getters from "shared/stores/user/getters";

export default {
  ...getters,
  hasAccessLevel: (state) => (level) =>
    state.accessLevels[level].includes(state.currentUser.access_level),
  hasSuperAccess() {
    return this.hasAccessLevel("super");
  },
  hasNormalAccess() {
    return this.hasAccessLevel("normal");
  },
  hasOrganisationEditAccess() {
    return this.hasAccessLevel("organisation_edit");
  },
  hasOrganisationReadAccess() {
    return this.hasAccessLevel("organisation_read");
  },
  hasLimitedAccess() {
    return this.hasAccessLevel("limited");
  },
  hasRosterAccess() {
    return this.hasAccessLevel("overnight_admin");
  },
  hasMediaAnalystAccess() {
    return this.hasAccessLevel("media_analyst");
  },
  hasDERAccess() {
    return this.hasAccessLevel("der");
  },
  isInsightsAMTeam() {
    const operationalTeam = this.currentUser.operational_team;

    if (!operationalTeam) return false;

    return operationalTeam.name.toLowerCase().includes("insights");
  },
  canManageOrganisation() {
    if (!this.hasOrganisationEditAccess) return false;

    if (this.hasSuperAccess || this.organisation?.status === "draft") {
      return true;
    }

    const allowedUserIds = this.organisation?.organisationsManagers.map(
      ({ managerId }) => Number(managerId)
    );

    const orgTeamId = this.organisation.operationalTeamId;
    const operationalTeam = this.currentUser.operational_team;
    if (!operationalTeam) return false;

    if (orgTeamId && Number(orgTeamId) === operationalTeam.id) {
      return true;
    }

    return allowedUserIds.includes(this.currentUser.id);
  },
};
