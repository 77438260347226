import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "organisations",
    path: "/organisations",
    component: () => import("pages/organisations/OrganisationsPage.vue"),
    meta: {
      accessLevel: "organisation_read",
      title: "Organisations",
    },
  },
  {
    name: "organisation-create",
    path: "/organisations/new",
    component: () =>
      import("pages/organisations/OrganisationTabInformation.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "Create Organisation",
    },
  },
  {
    name: "organisation-upsell-dashboard",
    path: "/organisations/upsell-dashboard",
    component: () => import("pages/organisations/UpsellDashboardPage.vue"),
    props: (route) => typecastRouteProps(route),
    meta: {
      accessLevel: "super",
      title: "Organisations Upsell Dashboard",
    },
  },
  {
    name: "organisation-tab",
    path: "/organisations/:organisationId/edit",
    props: true,
    component: () => import("pages/organisations/OrganisationTabs.vue"),
    meta: {
      title: "Edit Organisation",
    },
    children: [
      {
        name: "organisation-users",
        path: "users",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () => import("pages/organisations/OrganisationTabUsers.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Users",
        },
      },
      {
        name: "organisation-teams",
        path: "teams",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () => import("pages/organisations/OrganisationTabTeams.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Teams",
        },
      },
      {
        name: "organisation-features",
        path: "features",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabFeatures.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Features",
        },
      },
      {
        name: "organisation-content-licenses",
        path: "content_licenses",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabContentLicenses.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Content Licenses",
        },
      },
      {
        name: "organisation-managed-briefs",
        path: "managed-briefs",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabManagedBriefs.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Managed Briefs",
        },
      },
      {
        name: "organisation-information",
        path: "information",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabInformation.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Information",
        },
      },
      {
        name: "organisation-brands",
        path: "organisation-brands",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabBrands.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Brands",
        },
      },
      {
        name: "organisation-mention-streams",
        path: "mention-streams",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabMentionStreams.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Mention Streams",
        },
      },
      {
        name: "organisation-bookmark-streams",
        path: "bookmark-streams",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabBookmarkStreams.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Tagged Streams",
        },
      },
      {
        name: "organisation-notifications",
        path: "notifications",
        props: (route) => typecastRouteProps(route),
        component: () =>
          import("pages/organisations/OrganisationTabNotifications.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Notifications",
        },
      },
      {
        name: "organisation-scheduled-reports",
        path: "scheduled-reports",
        props: (route) => ({
          view: route.query.view,
          ...route.params,
          ...route.query,
        }),
        component: () =>
          import("pages/organisations/OrganisationTabScheduledReports.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Scheduled Reports",
        },
      },
      {
        name: "organisation-stats",
        path: "stats",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () => import("pages/organisations/OrganisationTabStats.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Stats",
        },
      },
      {
        name: "organisation-activities",
        path: "activities",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabActivities.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Activities",
        },
      },
      {
        name: "organisation-distribution-lists",
        path: "distribution-lists",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabDistributionLists.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Distribution Lists",
        },
      },
      {
        name: "organisation-transcript-templates",
        path: "transcript-templates",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabTranscriptTemplates.vue"),
        meta: {
          accessLevel: "organisation_edit",
          title: "Organisation Transcript Templates",
        },
      },
      {
        name: "organisation-transcript-turnaround-times",
        path: "transcript-turnaround-times",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import(
            "pages/organisations/OrganisationTabTranscriptTurnaroundTimes.vue"
          ),
        meta: {
          accessLevel: "organisation_edit",
          title: "Organisation Transcript Turnaround Times",
        },
      },
      {
        name: "organisation-outreach-campaign-templates",
        path: "outreach-campaign-templates",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import(
            "pages/organisations/OrganisationTabOutreachCampaignTemplates.vue"
          ),
        meta: {
          accessLevel: "organisation_edit",
          title: "Organisation Outreach Campaign Templates",
        },
      },
      {
        name: "upsell-registrations",
        path: "upsell-registrations",
        props: (route) => typecastRouteProps(route),
        component: () => import("pages/organisations/UpsellRegistrations.vue"),
        meta: {
          accessLevel: "organisation_edit",
          title: "Organisation Upsell Registrations",
        },
      },
      {
        name: "organisation-contracts",
        path: "contracts",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabContractsAndBilling.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Contracts & Billing",
        },
      },
      {
        name: "organisations-managers",
        path: "managers",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabManagers.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Managers",
        },
      },
      {
        name: "organisation-invoices",
        path: "invoices",
        props: (route) => ({
          ...route.params,
          ...route.query,
          organisationId: [route.params.organisationId],
          hideOrganisationsDropdown: true,
        }),
        component: () => import("pages/InvoiceList.vue"),
        meta: {
          accessLevel: "organisation_edit",
          title: "Organisation Invoices",
        },
      },
      {
        name: "organisation-usage",
        path: "usage",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabUsageAndAllowance.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Organisation Usage & Allowance",
        },
      },
      {
        name: "organisation-filter-sets",
        path: "filter-sets",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabFilterSets.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Filter Sets",
        },
      },
      {
        name: "organisation-brief-groups",
        path: "brief-groups",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabBriefGroups.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Brief Groups",
          angularName: "app.brief_groups.index",
        },
      },
      {
        name: "organisation-brief-sets",
        path: "brief-sets",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabBriefSets.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Brief Sets",
        },
      },
      {
        name: "organisation-social-streams",
        path: "social-streams",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabSocialStreams.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Social Streams",
        },
      },
      {
        name: "organisation-query-snippet-groups",
        path: "query-snippet-groups",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabQuerySnippetGroups.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Query Snippet Groups",
        },
      },
      {
        name: "organisation-query-snippets",
        path: "query-snippets",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabQuerySnippets.vue"),
        meta: {
          accessLevel: "organisation_read",
          title: "Query Snippets",
        },
      },
      {
        name: "organisation-clients",
        path: "clients",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabClients.vue"),
        meta: {
          accessLevel: "organisation_edit",
          title: "Clients",
        },
      },
      {
        name: "organisation-outreach-migrator",
        path: "outreach-migrator",
        props: (route) => ({ ...route.params, ...route.query }),
        component: () =>
          import("pages/organisations/OrganisationTabOutreachMigrator.vue"),
        meta: {
          accessLevel: "organisation_edit",
          title: "Outreach Migrator",
        },
      },
    ],
  },
];
