import { useUserStore } from "shared/stores/user";

const accessLevels = {
  super: ["super"],
  normal: [
    "normal",
    "super",
    "overnight_admin",
    "media_analyst",
    "content_admin",
  ],
  organisation_edit: [
    "normal",
    "super",
    "overnight_admin",
    "media_analyst",
    "content_admin",
  ],
  organisation_read: [
    "sales",
    "normal",
    "super",
    "overnight_admin",
    "media_analyst",
    "content_admin",
  ],
  limited: [
    "limited",
    "super",
    "normal",
    "overnight_admin",
    "media_analyst",
    "content_admin",
  ],
  overnight_admin: ["super", "overnight_admin"],
  media_analyst: [
    "super",
    "media_analyst",
    "external_media_analyst",
    "content_admin",
  ],
  der: [
    "normal",
    "super",
    "overnight_admin",
    "media_analyst",
    "content_admin",
    "external_der_analyst",
  ],
  copyright_reporting: ["super", "content_admin"],
  spelling_corrections: ["content_admin", "super"],
  content_removal: ["content_admin", "super"],
};

const adminRoles = {
  limited: "Limited",
  sales: "Sales",
  normal: "Normal",
  overnight_admin: "Overnight Admin",
  media_analyst: "Media Analyst",
  external_media_analyst: "External Media Analyst",
  external_der_analyst: "External DER Analyst",
  content_admin: "Content Admin",
  super: "Super",
};

export default () => {
  const userStore = useUserStore();

  return {
    currentUser: { access_level: ["limited"] },
    token: "",
    isLoggedIn: false,
    isAdminUser: false,
    loginFailed: false,
    organisation: {},
    ...userStore.$state,
    adminRoles,
    accessLevels,
  };
};
