import { Attr, BelongsTo, HasMany, HasOne, Model } from "spraypaint";

import { getLocaleText } from "shared/boot/i18n";
import {
  formatIntlDate,
  fromCurrentToGivenTimezone,
} from "shared/helpers/date";
import ApplicationRecord from "shared/services/spraypaint";

import ActiveStorageAttachment from "./ActiveStorageAttachment";
import EmailDomain from "./EmailDomain";
import MediaReleaseRecipient from "./MediaReleaseRecipient";
import MediaReleasesContactList from "./MediaReleasesContactList";
import MediaReleasesContactMailingList from "./MediaReleasesContactMailingList";
import MediaReleaseTemplate from "./MediaReleaseTemplate";
import Organisation from "./Organisation";
import OutreachActivity from "./OutreachActivity";
import User from "./User";

export const getMediaReleaseStatuses = () => [
  {
    label: getLocaleText("resources.media_release.statuses.draft"),
    value: "draft",
    backgroundColor: "#E6EBEF",
    color: "#707889",
  },
  {
    label: getLocaleText("resources.media_release.statuses.scheduled"),
    value: "scheduled",
    backgroundColor: "#D6E4FF",
    color: "#096DD7",
  },
  {
    label: getLocaleText("resources.media_release.statuses.sending"),
    value: "sending",
    backgroundColor: "#D6E4FF",
    color: "#096DD7",
  },
  {
    label: getLocaleText("resources.media_release.statuses.sent"),
    value: "sent",
    backgroundColor: "#C5F7D0",
    color: "#4BA05D",
  },
  {
    label: getLocaleText("resources.media_release.statuses.error"),
    value: "error",
    backgroundColor: "#FFCCC7",
    color: "#CD1323",
  },
];

type MediaReleaseStatus = "draft" | "scheduled" | "sending" | "sent" | "error";

const STATUSES: Record<MediaReleaseStatus, string> =
  getMediaReleaseStatuses().reduce<Record<string, string>>(
    (acc, { value }) => ({
      ...acc,
      [value]: value,
    }),
    {}
  );

export enum MediaReleaseType {
  MediaRelease = "media_release",
  Pitch = "pitch",
}

@Model()
class MediaRelease extends ApplicationRecord {
  static jsonapiType = "media_releases";

  static Status = STATUSES;

  @BelongsTo() user: User;

  @BelongsTo() organisation: Organisation;

  @BelongsTo() organisationBrand: Organisation;

  @BelongsTo() mediaReleaseTemplate: MediaReleaseTemplate;

  @BelongsTo() emailDomain: EmailDomain;

  @HasMany({ name: "taggable" }) tags: any[];

  @HasMany() mediaReleaseRecipients: MediaReleaseRecipient[];

  @HasMany() mediaReleasesContactLists: MediaReleasesContactList[];

  @HasMany()
  mediaReleasesContactMailingLists: MediaReleasesContactMailingList[];

  @HasMany() nonListRecipients: MediaReleaseRecipient[];

  @HasMany() activeStorageAttachments: ActiveStorageAttachment[];

  @HasMany() unsubscribedRecipients: MediaReleaseRecipient[];

  @HasOne({ name: "activity" }) outreachActivity: OutreachActivity;

  @Attr() userId: number;

  @Attr() organisationId: number;

  @Attr() organisationTeamId: number;

  @Attr() organisationBrandId: number;

  @Attr() mediaReleaseTemplateId: number;

  @Attr() emailDomainId: number;

  @Attr() recipients: MediaReleaseRecipient[];

  @Attr() emailRecipients: any[];

  @Attr() replyTo: string;

  @Attr() senderEmail: string;

  @Attr() fromName: string;

  @Attr() description: string;

  @Attr() title: string;

  @Attr() subject: string;

  @Attr() message: string;

  @Attr() status: MediaReleaseStatus;

  @Attr() notifyOnOpen: boolean;

  @Attr() notifyOnClick: boolean;

  @Attr() private: boolean;

  @Attr() scheduledAt: string;

  @Attr() personalised: boolean;

  @Attr() mediaReleaseType: MediaReleaseType;

  @Attr({ persist: false }) someRecipientMessagesPersonalised: boolean;

  @Attr({ persist: false }) allRecipientMessagesPersonalised: boolean;

  @Attr({ persist: false }) sentAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) archivedAt: string;

  @Attr() recipientCount: number;

  @Attr({ persist: false }) emailClickedCount: number;

  @Attr({ persist: false }) emailDeliveredCount: number;

  @Attr({ persist: false }) emailFailedCount: number;

  @Attr({ persist: false }) emailOpenedCount: number;

  @Attr({ persist: false }) unsubscribedRecipientEmails: string[];

  @Attr({ persist: false }) name: string;

  statusLabel() {
    if (this.isSent()) {
      return getLocaleText("resources.media_release.status_label_sent", {
        date: this.formattedSentAt(),
      });
    }

    if (this.scheduledAt) {
      if (this.status === (STATUSES.draft as MediaReleaseStatus)) {
        return getLocaleText(
          "resources.media_release.status_label_scheduled_at_draft",
          { date: this.formattedScheduledAt() }
        );
      }

      return getLocaleText(
        "resources.media_release.status_label_scheduled_at",
        { date: this.formattedScheduledAt() }
      );
    }

    return getMediaReleaseStatuses().find(({ value }) => value === this.status)
      ?.label;
  }

  scheduledAtExpired(timezone: string) {
    return (
      ![STATUSES.sending, STATUSES.sent, STATUSES.error].includes(
        this.status
      ) &&
      this.scheduledAt &&
      fromCurrentToGivenTimezone(new Date(this.scheduledAt), timezone) <
        fromCurrentToGivenTimezone(new Date(), timezone)
    );
  }

  formattedScheduledAt() {
    return formatIntlDate(this.scheduledAt, {
      dateStyle: "short",
      timeStyle: "medium",
    });
  }

  formattedSentAt() {
    return formatIntlDate(this.sentAt, {
      dateStyle: "short",
      timeStyle: "medium",
    });
  }

  isSent() {
    return this.status === STATUSES.sent;
  }
}

export default MediaRelease;
