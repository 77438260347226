import { storeToRefs } from "pinia";

import { ErrorDialog } from "shared/boot/alert";
import { identify as identifyAnalytics } from "shared/boot/analytics";
import { $streemApiAdmin, $streemApiV1 } from "shared/boot/api";
import { identify as identifyRollbar } from "shared/boot/rollbar";
import { parseDate } from "shared/helpers/date";
import DateRange from "shared/helpers/DateRange";
import {
  MediaRequest,
  MentionExpectation,
  Organisation,
  OrganisationReportComment,
  Role,
  TranscriptRequest,
  UserRequest,
} from "shared/resources";
import { useGlobalStore } from "shared/stores/global";
import { useOrganisationFeaturesStore } from "shared/stores/organisationFeatures";

import { useUserStore } from "src/stores/user";

const handleErrors = true;
const lastWeek = DateRange.lastSevenDays();

export default {
  setGlobalDataLoading() {
    this.globalDataLoaded = false;
    this.globalDataError = false;
  },
  setGlobalDataLoaded() {
    this.globalDataLoaded = true;
    this.globalDataError = false;
  },
  async getAppData() {
    this.setGlobalDataLoading();
    const userStore = useUserStore();

    await userStore.getCurrentUser();

    const { currentUser, hasOrganisationReadAccess } = storeToRefs(userStore);

    const loads = [];

    if (currentUser.value.admin) {
      loads.push(this.getAccountManagers());
    }

    if (hasOrganisationReadAccess.value) {
      loads.push(
        ...[
          this.getCategories(),
          this.getRoles(),
          this.getPermissions(),
          this.getContractServices(),
        ]
      );
    }

    const result = await Promise.all(loads);

    identifyRollbar({
      id: currentUser.value.id,
      username: `${currentUser.value.first_name} ${currentUser.value.last_name}`,
      email: currentUser.value.email,
    });

    identifyAnalytics(currentUser.value.id, {
      user_hash: currentUser.value.user_hash,
      email: currentUser.value.email,
      name: `${currentUser.value.first_name} ${currentUser.value.last_name}`,
      first_name: currentUser.value.first_name,
      last_name: currentUser.value.last_name,
      global_customer_id: "",
    });

    this.setGlobalDataLoaded();

    return result;
  },
  async getMenuCounter() {
    const userStore = useUserStore();
    const { hasNormalAccess } = storeToRefs(userStore);

    const loads = [
      this.getExcludedMentionsCount(),
      this.getUserRequestsCount(),
    ];

    if (hasNormalAccess.value) {
      const additionalLoads = [
        this.getTvMentionReviewsCount(),
        this.getRadioMentionReviewsCount(),
        this.getOrganisationCommentsCount(),
        this.getOvernightFeedbackCount(),
        this.getLastWeekTranscriptsCount(),
        this.getMediaRequestsCount(),
        this.getMentionExpectationsCount(),
      ];

      loads.push(...additionalLoads);
    }

    return Promise.all(loads);
  },
  getTvMentionReviewsCount() {
    const params = {
      target_type: ["TvSuper", "Caption", "TvCaption", "TvLogoAppearance"],
      reviewed: false,
      include_admin_only_mention_reviews: true,
      limit: 0,
    };

    return $streemApiAdmin
      .get("mention_reviews", { params, handleErrors })
      .then((response) =>
        this.setTvMentionReviewsCount(response.headers["x-total-count"])
      );
  },
  setTvMentionReviewsCount(count) {
    this.mentionReviews.tvCount = count;
  },
  getRadioMentionReviewsCount() {
    const params = {
      target_type: ["RadioClip"],
      reviewed: false,
      limit: 0,
      include_admin_only_mention_reviews: true,
    };

    return $streemApiAdmin
      .get("mention_reviews", { params, handleErrors })
      .then((response) =>
        this.setRadioMentionReviewsCount(response.headers["x-total-count"])
      );
  },
  setRadioMentionReviewsCount(count) {
    this.mentionReviews.radioCount = count;
  },
  getMentionExpectationsCount() {
    const params = {
      status: ["pending", "in_progress"],
    };

    return MentionExpectation.where(params)
      .per(0)
      .stats({ total: "count", total_to_do: "count" })
      .all()
      .then((response) => {
        const toDo = response.meta.stats.total_to_do.count;
        const expected = response.meta.stats.total.count - toDo;

        this.setMentionExpectationsCount({ toDo, expected });
      })
      .catch(() => {
        // do nothing
      });
  },
  setMentionExpectationsCount(count) {
    this.mentionExpectationsCount = count;
  },
  getMediaRequestsCount() {
    const params = {
      archived: { match: "false" },
      createdAt: {
        lt: parseDate(lastWeek.before).toISOString(),
        gt: parseDate(lastWeek.after).toISOString(),
      },
      status: [
        "Incomplete",
        "In Progress",
        "Ready",
        "Exporting",
        "speech2text",
        "Clip ready",
        "Sent to mediatrack",
        "Error",
      ],
    };

    return MediaRequest.where(params)
      .stats({ total: "count" })
      .all()
      .then((response) =>
        this.setMediaRequestsCount(response.meta.stats.total.count)
      )
      .catch(() => {
        // do nothing
      });
  },
  setMediaRequestsCount(count) {
    this.mediaRequestsCount = count;
  },
  getUserRequestsCount() {
    return UserRequest.where({ status: { eq: "pending" } })
      .stats({ total: "count" })
      .all()
      .then((response) => {
        this.setUserRequestsCount(response.meta.stats.total.count);
      })
      .catch(() => {
        // do nothing
      });
  },
  setUserRequestsCount(count) {
    this.userRequestsCount = count;
  },
  getOrganisationFeatures(organisationId) {
    return Organisation.select(["id", "region", "time_zone"])
      .selectExtra(["features"])
      .find(organisationId)
      .then((response) => {
        this.currentOrganisation = {
          id: response.data.id,
          region: response.data.region,
          time_zone: response.data.timeZone,
        };

        useOrganisationFeaturesStore().resetOrganisationFeatures(
          // transform array to object format as expected by shared components for now
          response.data.features.reduce((acc, item) => {
            acc[item] = {};

            return acc;
          }, {}),
          { root: true }
        );
      })
      .catch((error) =>
        ErrorDialog("Failed to load organisation features", error)
      );
  },
  getLocations() {
    if (this.locations.length) {
      return Promise.resolve();
    }

    return $streemApiV1
      .get("locations", {
        params: {
          admin_visible: true,
        },
        handleErrors,
      })
      .then((response) => {
        this.setLocations(response.data);
      });
  },
  setLocations(locations) {
    // shared filters used in admin depend on the global store locations
    const globalStore = useGlobalStore();
    globalStore.setLocations(locations);

    this.locations = locations;
  },
  getCategories() {
    return $streemApiV1
      .get("categories", {
        params: {
          include_social: true,
        },
        handleErrors,
      })
      .then((response) => {
        this.setCategories(response.data);
      });
  },
  setCategories(data) {
    // shared filters used in admin depend on the global store categories
    const globalStore = useGlobalStore();
    globalStore.setCategories(data);

    this.categories = data.filter(({ social }) => !social);
    this.socialCategories = data.filter(({ social }) => social);
  },
  getOrganisationCommentsCount() {
    const categories = [
      "Inclusion",
      "Exclusion",
      "Client Media Update",
      "Feedback",
      "Spec Update",
      "Keyword Update",
    ];

    return OrganisationReportComment.where({
      closed: false,
      parentComments: true,
      category: categories,
    })
      .stats({ total: "count" })
      .per(0)
      .all()
      .then((response) => {
        this.setOrganisationReportComments(response.meta.stats.total.count);
      })
      .catch(() => {
        // do nothing
      });
  },
  setOrganisationReportComments(count) {
    this.organisationReportComments = count;
  },
  getOvernightFeedbackCount() {
    return OrganisationReportComment.where({
      closed: false,
      parentComments: true,
      category: "AM Review",
    })
      .stats({ total: "count" })
      .per(0)
      .all()
      .then((response) => {
        this.setOvernightFeedbackCount(response.meta.stats.total.count);
      })
      .catch(() => {
        // do nothing
      });
  },
  setOvernightFeedbackCount(count) {
    this.overnightComments = count;
  },
  getLastWeekTranscriptsCount() {
    return TranscriptRequest.where({
      archived: { match: "false" },
      status: [
        "Incomplete",
        "Ready",
        "Exporting",
        "speech2text",
        "Clip ready",
        "Sent for manual transcription",
      ],
      createdAt: {
        lt: parseDate(lastWeek.before).toISOString(),
        gt: parseDate(lastWeek.after).toISOString(),
      },
    })
      .stats({ total: "count" })
      .per(0)
      .all()
      .then((response) => {
        this.setTranscriptRequestsCount(response.meta.stats.total.count);
      })
      .catch(() => {
        // do nothing
      });
  },
  setTranscriptRequestsCount(count) {
    this.transcriptRequestsCount = count;
  },
  getExcludedMentionsCount() {
    return $streemApiAdmin
      .get("excluded_mentions", { params: { limit: 0 }, handleErrors })
      .then((response) => {
        this.setExcludedMentionsCount(response.headers["x-total-count"]);
      });
  },
  setExcludedMentionsCount(count) {
    this.excludedMentionsCount = count;
  },
  getRoles() {
    return Role.includes("permissionsRoles")
      .per(999)
      .all()
      .then((response) => {
        this.setRoles(response.data);
      })
      .catch((error) => ErrorDialog("Failed to roles", error));
  },
  setRoles(roles) {
    this.roles = roles;
  },
  getPermissions() {
    return $streemApiAdmin
      .get("permissions", { handleErrors })
      .then((response) => {
        this.setPermissions(response.data);
      });
  },
  setPermissions(permissions) {
    this.permissions = permissions;
  },
  getAccountManagers() {
    return Organisation.where({
      archived: false,
      admin: true,
      users: { active: true },
    })
      .includes("users")
      .select({ users: ["name", "email"] })
      .all()
      .then((organisations) => {
        const users = organisations.data
          .map((organisation) => organisation.users)
          .flat();
        this.setAccountManagers(users);
      })
      .catch((error) => ErrorDialog("Failed to load account managers", error));
  },
  setAccountManagers(accountManagers) {
    this.accountManagers = accountManagers;
  },
  toggleHideHeader() {
    this.hideHeader = !this.hideHeader;
  },
  setHideHeader(value) {
    this.hideHeader = value;
  },
  getContractServices() {
    return $streemApiAdmin
      .get("organisation_contract_services/list_services")
      .then((response) => {
        this.setContractServices(response.data);
      });
  },
  setContractServices(contractServices) {
    this.contractServices = contractServices;
  },
};
